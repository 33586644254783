.resume{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin:0;
}

.resume-img{
  width:10rem;
  transition: transform 0.3s ease;
}
.resume-img:hover{
  object-fit: cover;
  transform: scale(1.1);
}

.resume-download-btn {
  margin: 3rem 0;
  padding: 0.5rem 2.5rem;
  background-color: white;
  color: var(--background);
  border-radius: 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: none;
  gap: 1rem;
}

.resume-download-btn:hover {
  background-color: var(--highlight);
}

.resume-download-btn > p {
  margin: 0;
}

#contact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.contact-page-title{
  margin: 1rem 0;
  font-size: 3rem;
}
.contact-heading{
  margin: 1rem 0;
  font-size: 3rem;
}

.contact-descr{
  padding: 1rem;
  font-size: medium;
  font-weight: 300;
}

.contact-form{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90vw;
  max-width: 60rem;
}

.name, .email, .message {
  font-size: medium;
  color: white;
  border: none;
  background-color: var(--light-background);
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 1rem 1rem;
}
.message{
  height:25vh;
}

.submit-btn{
  background-color: white;
  border:none;
  padding: 0.75rem 3.5rem;
  border-radius: 0.5rem;
  margin: 1rem;
}
.submit-btn:hover{
  /* background-color: var(--highlight); */
}

.download-img {
  height: 1rem;
  width: .5rem;
  object-fit: cover;
  margin: 0;
} 

.links{
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-img{
  object-fit: cover;
  height: 3rem;
  margin: 1rem 1rem;
  margin-bottom: 1.5rem;
}
