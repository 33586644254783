.navbar{
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
  flex: 0 1 auto;
  /* overflow: hidden; */
  background-color: var(--background);
}

.logo{
  object-fit: cover;
  height: 4rem;
  width: 4rem;
  border-radius: 1rem;
}

.desktop-menu-list-item{
  margin: 1rem;
  cursor: pointer;
  border-bottom: 10px white;
}
.desktop-menu-list-item:hover{
  padding-bottom: 0.5rem;
  color: var(--highlight);
  border-bottom: 3px solid var(--highlight);
}


.desktop-menu-btn{
  border: none;
  background:white;
  color:var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 2rem;
  cursor:pointer;
  white-space:nowrap;
}
.desktop-menu-btn:hover{
  background-color: var(--highlight);
}

.desktop-menu-img{
  object-fit: cover;
  height: 1rem;
  width: 1rem;
  margin: 1rem;
}

.active{
  color: var(--highlight);
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--highlight)
}


.desktop-menu{
  width:100%;
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  margin:2rem;
}

.ham-menu{
  height:75px;
  color: white;
}

.nav-menu{
  margin-top: 0.5rem;
  display: flex;
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  height: fit-content;
  min-width: 15rem;
  background-color: var(--light-background);
  border-radius: 1rem; 
  /* overflow:auto; */
}

.desktop-menu-list-item{
  color: white;
  /* padding: 0.5rem auto; */
  /* margin: 0.25rem; */
  background: var(--background)

}

.ham-menu{
  display: none;
  object-fit: cover;
  height: 5rem;
}

.ham-list-menu {
  color:white;
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background-color: var(--background);
}
.ham-list-menu:hover{
  padding-bottom: 0.5rem;
  color: var(--highlight);
  border-bottom: 3px solid var(--highlight);
}

@media screen and (max-width:700px) {
  .ham-menu{
    display: flex;
  }
  .desktop-menu{
    display: none;
  }
  .desktop-menu-btn{
    display: none;
  }
}