#intro{
  height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 90rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display:flex;
  justify-content: space-between;
}

.intro-content{
  height: 100vh;
  width: 100vh;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction:column;
  justify-content:center;
  width:100%;
  max-width:40rem;
}

.hello {
  font-size: 1.75rem;
  font-weight: 100;
}

.intro-name{
  color: var(--highlight);
}

.intro-para{
  font-size: medium;
  font-weight: 300;
  margin-top: 0.5rem;
  line-height: 1.75;
}

.btn{
  background-color:white;
  color: var(--background);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 0.75rem 2rem;
  border: none;
  width: 10rem;
}
.btn:hover{
  background-color: var(--highlight);
}

.btn-img{
  object-fit: cover;
  margin: 0 0.25rem;
  height: 1rem;
}

.bg{
  z-index: -1;
  position: absolute;
  top: -4rem;
  right: 0;
  object-fit: cover;
  height: 100vh;
}


@media screen and (max-width: 1200px) {
  .intro-content{
    max-width:35rem;
  }
  .bg {
    right: -10vw
  }
}

@media screen and (max-width: 1024px) {
  .intro-content{
    max-width:35rem;
  }
  .bg {
    right: -23vw
  }
}

@media screen and (max-width: 850px) {
  .intro-content{
    max-width: 445px;
  }
  .intro-name{
    text-align: end;
  }
  .bg {
    right: -34vw
  }
}

@media screen and (max-width: 670px) {
  #intro{
    height:120vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .intro-content{
    flex: 0 1 auto;
    max-width: 55rem;
  }
  .hello{
    margin-bottom: 1rem;
  }
  .intro-text{
    font-size:50px;
    flex: 0 1 auto;
  }
  .intro-para {
    text-align: center;
    width:20rem;
    align-self:center;
  }
  .intro-name{
    font-size:105%;
  }
  .btn{
    display:none;
  }
  .bg {
    margin-left:1rem;
    flex: 0 2 auto;
    z-index: -1;
    position: relative;
    top: -4rem;
    right: 0;
    height: 70vh;
  }
}

@media screen and (max-width: 505px) {
  #intro{
    height: auto;
  }
  .intro-content{
    height:100%;
  }
  .hello {
    font-size:large;
  }
  .intro-text{
    font-size:30px;
  }
  .bg{
    height: 50vh;
  }
}

@media screen and (min-width: 100px) and ( max-height: 600px) {
  #intro{
    height: 100vh;
  }
  .intro-content{
    /* height:100%; */
  }
  .hello {
    font-size:large;
  }
  .intro-text{
    font-size:30px;
  }
  .bg{
    height:fit-content;
    width:70%;
    right: -19vw;
    position:absolute;
    flex: 0 1 auto;
  }
}

