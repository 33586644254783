#skills{
  overflow:hidden;
  width:100vw;
  max-width: 65rem;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 1rem auto;
  margin-top: 3rem;
}

.skill-title{
  margin: 1rem 0;
  font-size: 3rem;
}

.skill-descr{
  font-weight: 300;
  font-size: 1.2rem;
  padding: 0 2rem;
}

.skill-bars{
  margin: 1.5rem;
  width:100vw;
  max-width:80%;
  text-align: left;
  display:flex;
  flex-direction: column;
}

.skill-bar{
  margin: 0.5rem;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  background-color: var(--light-background);
  display:flex;
  flex-direction: row;
}

.skill-bar-img{
  object-fit: cover;
  width: 3rem;
  height: 3rem;
  margin-right: 1.5rem;
}

.skill-bar-text>p {
  font-size: 0.9rem;
  font-weight: 200;
}

@media screen and (max-width:750px) {
  .skill-bar-text>p{
    font-size:1.75vw;
  }
}


@media screen and (max-width: 670px) {
  .skill-bar-text>p{
    font-size:2.5vw;
  }
}

@media screen and (max-width:505px) {
  .skill-bar-text>p{
    font-size:3vw;
  }
  .skill-bar-text>h2{
    font-size: 5vw;
  }
  .skill-bar-img{
    width: 3rem;
    height: 3rem;
  }
}
@media screen and (max-width:420px) {
  .skill-bar-text>p{
    font-size:4vw;
  }
}
