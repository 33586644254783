#projects{
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: content;
  text-align: center;
  padding:0 1rem;
  margin: 0 auto;
  margin-top: 2rem;
}

.projects-title{
  margin: 1rem 0;
  font-size: 3rem;
}

.projects-descr{
  font-weight: 300;
  font-size: 1rem;
  max-width: 45rem;
}

.projects-imgs{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 65rem;
  flex-wrap:wrap;
}

.project-card {
  position:relative;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Shaded overlay */
  border-radius: 0.25rem;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  gap:2rem;
  font-size: large;
  font-weight:400;
  text-decoration: underline;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.overlay a {
  color:white;

}
.project-card:hover .overlay {
  opacity: 1;
  color:white;
}

.projects-img{
  object-fit: cover;
  height: 20rem;
  width:20rem;
  margin:0.5rem;
  border-radius: 0.25rem;
  transition: transform 0.3s ease;
}
.projects-img:hover{
  object-fit: cover;
  transform: scale(1.05);
}

.projects-btn{
  margin: 3rem 0;
  padding:0.5rem 2.5rem;
  background-color: white;
  color: var(--background);
  border-radius: 0.5rem;
  font-size: 1rem;
}
.projects-btn:hover{
  background-color: var(--highlight);
}

@media screen and (max-width:590px) {
  .projects-img{
    height:48vw;
  }
}